import { withNamespaces } from 'react-i18next';
import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';

// Components
import Banner from '@components/partials/Banner';
import MoviesNFestsNew from '@components/partials/MoviesNFestsNew';
import OnDemandInfo from '@components/partials/OnDemandInfo';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dropdown, Modal, Spinner } from 'react-bootstrap';
import { useGetBannersQuery } from '@store/rtk/rtk.banner';
import { useGetNowShowingQuery } from '@store/rtk/rtk.movieLists';
import {Helmet} from "react-helmet";

// Images

const NowShowingContainer = ({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const organization = useSelector((state) => state.movies.organizationDetails);
  const selected_language = useSelector((state) => state.movies.selected_language);
  const { iso_2, lang_id, lang_name } = selected_language;

  const { data: banners = [] } = useGetBannersQuery();
  const { data: todaysScreeningsMovies, isLoading: nowShowingLoading } =
    useGetNowShowingQuery({ limit: 1000, currentPage: 1 });

  const todaysScreeningsLabeledMovies = useSelector(
    (state) => state.homepage.todaysScreeningsLabeledMovies,
  );

  const cms_settings = useSelector((state) => state.homepage.cms_settings);
  const CMSLOADER = useSelector((state) => state.homepage.cmsLoader);

  const nowShowingBanners = banners.filter((x) => {
    if (x?.movie_id) {
      // if (x?.movie_banner_type_id?.split(',')?.includes('1')) {
      //   return x;
      // }

      return x?.now_showing_count > 0;
    } else {
      return x;
    }
  });

  const isItemActive = (item, CMSLOADER, cms_settings) => {
    if (!CMSLOADER) {
      if (cms_settings && cms_settings.length > 0) {
        if (cms_settings[0][item] == 'Y') {
          return true;
        } else if (cms_settings[0][item] == 'N') {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  return (
    <>
    <Helmet>
        <title>Movie Listing:Now Showing at Star Cinemas</title>
        <meta name="description" content={` Explore this page`} />
      </Helmet>
      <Banner banners={nowShowingBanners} showOnly={1} />

      <div className="container-fluid">
        {/* Movie Sections */}
        {todaysScreeningsMovies?.data?.length > 0 ? (
          <MoviesNFestsNew
            section={1}
            moviesArray={todaysScreeningsMovies?.data}
            subCategory={''}
            category={t('Now Showing')}
            movieCount={todaysScreeningsMovies?.data?.length}
            type="now_showing"
            // slider
            showMovieList={{ category_id: 1 }}
          />
        ) : nowShowingLoading ? (
          <section className="row">
            <div className="col-12 text-center">
              <Spinner animation="border" role="status" className="mx-auto" />
            </div>
          </section>
        ) : (
          <section className="row">
            <div className="col-12 text-center">
              <h6 className="mx-auto mb-4">{t('common.No movies found')}</h6>
            </div>
          </section>
        )}
        {isItemActive('show_on_demand', CMSLOADER, cms_settings) && (
          <OnDemandInfo />
        )}
      </div>
    </>
  );
};

export default withNamespaces()(NowShowingContainer);
