import React, { useEffect, useState, useContext, useRef } from 'react';
import { withNamespaces } from 'react-i18next';
import GenericService from '@apiService/GenericService';
import { useDispatch, useSelector } from 'react-redux';
// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import {Helmet} from "react-helmet";

const TnCNewContainer = ({ t }) => {
  const dispatch = useDispatch();
  const [cms_pages_data, setcms_pages_data] = useState([]);
  const MspagesDataFunc = () => async (dispatch) => {
    try {
      // dispatch({ type: ADVERTISE_PDF_DATA });
      // const { data } = await CinemaService.GetAdvertisePdfData();
      const response = await GenericService.GetMsPage(2);
      const { data } = response;
      if (data?.status) {
        setcms_pages_data(data.Records);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    dispatch(MspagesDataFunc());
  }, []);

  let tnc_data = cms_pages_data?.map((x) => {
    return x.page_content;
  });
  // console.log(cms_pages_data, 'cms_pages_data');
  // console.log(tnc_data.toString(), 'tnc_data');
  return (
    <>
      <Helmet>
        <title>Star Cinemas terms:Read Star Cinemas' terms and cinema privacy policy for UAE. Explore our ticket booking terms in the UAE for a smooth and secure movie experience.</title>
        <meta name="description" content={`Read Star Cinemas' terms and cinema privacy policy for UAE. Explore our ticket booking terms in the UAE for a smooth and secure movie experience.`} />
      </Helmet>
      <div className="tnc_new align_new">
        <div className="left_right_align">
          <BreadCrumb
            firstHalf={`${t('More')} >`}
            secondHalf={`${t('Terms')} & ${t('Conditions')}`}
          />
          <CenteredTitle
            firstHalf={`${t('Terms')} & ${t('Conditions')}`}
            secondHalf={''}
          />
        </div>
        <section className="row">
          <article className="col-lg-10 col-xl-12 tnc_back">
            <div
              className=""
              dangerouslySetInnerHTML={{
                __html: tnc_data?.toString(),
              }}
            />
          </article>
          {false && (
            <article className="col-lg-10 col-xl-12 tnc_back">
              <div>
                <p>
                  <span className="tnc_new_text_highlighted">
                    Operator's and Consumer's Code of Conduct
                    {/* {t('generalTermsAndConditionsLine1Text1')} */}
                  </span>
                  {/* {' '} */}
                  {/* {t('generalTermsAndConditionsLine1Text2')} */}
                  <div>
                    When using our website, you must comply with the terms and
                    conditions of use. We may change these terms and conditions over
                    time, so please read them every time you use this website. By
                    continuing to use this website, you accept the current terms and
                    conditions of use. If you don't agree with these terms and
                    conditions, please refrain from using this website. For further
                    information or customer support, contact us
                    by: info@starcinemas.com or call us at +971 47702121
                  </div>
                </p>
              </div>

              <div>
                <p>
                  <span className="tnc_new_text_highlighted">
                    Cinema viewers obligations
                    {/* {t('generalTermsAndConditionsLine2Text1')} */}
                  </span>{' '}
                  {/* {t('generalTermsAndConditionsLine2Text2')} */}
                </p>
                <p className="tnc_new_text_highlighted">Talking</p>
                {/* <div> */}
                <ol style={{ listStyleType: 'disc' }}>
                  <li>
                    Patrons must refrain from talking or communicating in any manner
                    that is disruptive to other Patrons while the movie in playing.
                    Please be considerate to those around you
                  </li>
                </ol>

                {/* </div> */}
              </div>

              <p className="tnc_new_text_highlighted mb-2">
                {/* {t('generalProvisions')} */}
                Eating & smoking
              </p>
              <div>
                <ol style={{ listStyleType: 'disc' }}>
                  <li>
                    Outside food is strictly prohibited within cinema premises
                  </li>
                  <li>Smoking is strictly prohibited within cinema premises</li>
                </ol>
              </div>
              <p className="tnc_new_text_highlighted mb-2">
                Photography & Videography
              </p>
              <div>
                <ol style={{ listStyleType: 'disc' }}>
                  <li>
                    Patrons are strictly prohibited from video recording or taking
                    pictures of any trailer or movie within the cinema
                  </li>
                </ol>
              </div>
              <p className="tnc_new_text_highlighted mb-2">Seating & Ticketing</p>
              <div>
                <ol style={{ listStyleType: 'disc' }}>
                  <li>
                    Patrons are not allowed to place their feet or shoes on cinema
                    seats
                  </li>
                  <li>
                    Patrons must retain their ticket stub/cinema ticket; failing to
                    show a ticket upon request or sitting in an unassigned seat is
                    prohibited
                  </li>
                  <li>
                    Patrons are required to arrive on time to avoid disturbing other
                    viewers
                  </li>
                  <li>
                    3D glass required for kids below 3-year-old for 3D movies.
                  </li>
                  <li>
                    Children above 3-year-old require ticket. Proof of age can be
                    requested by Star Cinemas
                  </li>
                </ol>
              </div>
              <p className="tnc_new_text_highlighted mb-2">Movie Ratings</p>
              <div>
                All patrons are mandated to abide the movie rating decided by
                National Media Council, UAE as per below classifications
              </div>
              <div>
                <p>
                  <span style={{ color: ' rgb(238, 21, 252)' }}>G</span>
                  <span>- For public viewing suitable for all ages</span>
                </p>
                <p>
                  <span style={{ color: ' rgb(238, 21, 252)' }}>PG</span>
                  <span>- For public viewing with adult supervision</span>
                </p>
                <p>
                  <span style={{ color: ' rgb(238, 21, 252)' }}>PG 13</span>
                  <span>- Ages 13 below allowed with adult supervision</span>
                </p>
                <p>
                  <span style={{ color: ' rgb(238, 21, 252)' }}>PG 15</span>
                  <span>- Ages 15 & below allowed with adult supervision</span>
                </p>
                <p>
                  <span style={{ color: ' rgb(238, 21, 252)' }}>15+</span>
                  <span>- Ages 15 & above only Infants not allowed</span>
                </p>
                <p>
                  <span style={{ color: ' rgb(238, 21, 252)' }}>18+</span>
                  <span>- Ages 18 & above only Infants not allowed</span>
                </p>
                <p>
                  <span style={{ color: ' rgb(238, 21, 252)' }}>21+</span>
                  <span>- Ages 21 & above only Infants not allowed</span>
                </p>
                <div>
                  Star Cinemas reserves the right to request ID for age verification
                  purposes
                </div>
              </div>
              <p className="tnc_new_text_highlighted mb-2">To the Parents</p>
              <div>
                <ol style={{ listStyleType: 'disc' }}>
                  <li>
                    Patrons should ensure their children are not left unattended and
                    should try to minimize noise and disruption
                  </li>
                  <li>
                    Patrons must abide by the guidelines set by National Media
                    Council, UAE for movie censors and ratings
                  </li>
                </ol>
              </div>
              <p className="tnc_new_text_highlighted mb-2">Security</p>
              <div>
                Cinema operator reserves the right to check your bags brought into
                the cinema
              </div>
              <p className="tnc_new_text_highlighted mb-2">Other</p>
              <div>
                <ol>
                  <li>
                    Patrons should refrain from using any electronic devices
                    (including phones, tablets or gaming devices and laser) during
                    the movie
                  </li>
                  <li>
                    Failing to follow instructions of cinema employees, is
                    prohibited
                  </li>
                  <li>
                    Patrons are not allowed to assault operator’s employees verbally
                    or physically
                  </li>
                </ol>
              </div>
              <p className="tnc_new_text_highlighted mb-2">
                Operator's obligations towards viewers
              </p>
              <div>
                <ol style={{ listStyleType: 'disc' }}>
                  <li>
                    Star Cinemas shall ensure their locations are maintained and in
                    good condition. Shall provide highly trained staff that is
                    capable to manage and handle client inquiries, requests, and
                    complaints.
                  </li>
                  <li>
                    Star Cinemas shall provide a variety of movies that cater to
                    different consumers’ backgrounds, ages, and preferences.
                  </li>
                  <li>
                    Star Cinemas aim to have different ticket pricing that matches
                    consumer needs.
                  </li>
                  <li>
                    Star Cinemas will offer various food & beverages items to match
                    consumer needs.
                  </li>
                </ol>
              </div>
              <p className="tnc_new_text_highlighted mb-2">Movie Experience</p>
              <div>
                <ol style={{ listStyleType: 'disc' }}>
                  <li>
                    Star Cinemas aims to provide a quality experience by having
                    cinema screens, seating, projectors, and sound systems in line
                    with international cinema standards
                  </li>
                  <li>
                    Star Cinemas will start movies on time without any prolonged
                    delays
                  </li>
                </ol>
              </div>
              <p className="tnc_new_text_highlighted mb-2">
                General Terms and Conditions
              </p>

              <p className="tnc_new_text_highlighted mb-2">Returns Policy</p>
              <div>
                Movie tickets sold online for STAR Cinemas are done so on a no
                returns, exchange, or cancellation basis. Refunds or complimentary
                passes will not be issued for incorrect selection of showtimes,
                dates, screening types (families or singles) ratings, or movies.
                Please review your booking summary carefully and double-check that
                the session and ticket information under the “You Have Selected”
                heading is correct before proceeding to checkout. By completing your
                transaction and clicking on the “Book Now” button, you agree to
                accept this policy
                <p>
                  <ol>
                    <li>
                      All credit/debit cards details and personally identifiable
                      information will NOT be stored, sold, shared, rented, or
                      leased to any third parties.
                    </li>
                    <li>
                      https://starcinemas.ae/ will NOT deal or provide any services
                      or products to any of OFAC (Office of Foreign Assets Control)
                      sanctions countries in accordance with the{' '}
                    </li>
                    <li>
                      We accept payments online using Visa and MasterCard
                      credit/debit card.
                    </li>
                    <li>
                      Refunds will be done only through the Original Mode of
                      Payment.
                    </li>
                  </ol>
                </p>
              </div>
              <p className="tnc_new_text_highlighted mb-2">Intellectual Property</p>
              <div>
                <ol>
                  <li>
                    STAR Cinemas owns all the material on this website. This
                    includes all text, designs, logos, graphics, computer programs,
                    audio, video, and code (including HTML) in any form unless
                    stated otherwise in these terms and conditions of use. None of
                    the material on this website may be reproduced, downloaded,
                    distributed, copied, republished, displayed, or transmitted in
                    any form whatsoever without our written permission. We grant you
                    permission to view this website with a web browser provided that
                    you do not:
                  </li>
                  <li>Modify the material on this website</li>
                  <li>Resell the material on this website</li>
                  <li>
                    Create derivative works from the material on this website.
                  </li>
                  <li>
                    We may withdraw this permission at any time without notice to
                    you.
                  </li>
                  <li>
                    We may in our absolute discretion publish on this website
                    material or information that you submit to us for:
                  </li>
                  <li>Participation in competitions</li>
                  <li>Any other reason</li>
                  <li>
                    By submitting information or material to us, you grant to us an
                    irrevocable, perpetual, royalty-free, non-exclusive, worldwide
                    license to
                  </li>
                  <li>
                    Use, reproduce, modify, sublicense, redistribute, adapt,
                    transmit, publish, broadcast and display that information
                  </li>
                  <li>Material and create derivative works from it;</li>
                  <li>Sublicense any of the foregoing rights to third parties.</li>
                </ol>
              </div>
              <p className="tnc_new_text_highlighted mb-2">Conduct</p>
              <div>
                <p>In using this website, you must not:</p>
                <div>
                  <ol>
                    <li>
                      Disrupt the operation or security of this website or any
                      accounts, servers, or networks connected or accessible through
                      this website;
                    </li>
                    <li>
                      Use this website in a way that may harass, annoy or disrupt
                      any third person, including a third person who may receive
                      messages as a result of your use of this website;
                    </li>
                    <li>
                      Submit any unlawful, threatening, abusive, defamatory,
                      obscene, vulgar, pornographic, profane, or indecent
                      information or material of any kind, including without
                      limitation any material constituting or encouraging conduct
                      that would constitute a criminal offense, give rise to civil
                      liability or otherwise violate any applicable law; submit any
                      material of any kind which violates or infringes the rights of
                      any other person, including material which is an invasion of
                      any privacy rights, which is protected by copyright, trademark
                      or any other proprietary right without first obtaining the
                      permission of the owner or relevant right holder;
                    </li>
                    <li>
                      Submit any material of any kind which contains a virus or
                      other harmful component;
                    </li>
                    <li>
                      Modify or delete any content on this website or add any
                      content to this website; or
                    </li>
                    <li>
                      Attempt to gain unauthorized access to any part of this
                      website.
                    </li>
                    <li>
                      We reserve the right to cooperate fully with any law
                      enforcement authority in any jurisdiction in respect of any
                      lawful direction or request to disclose the identity or other
                      information in respect of anyone posting any materials which
                      violate any applicable or relevant law.
                    </li>
                  </ol>
                </div>
              </div>
              <p className="tnc_new_text_highlighted mb-2">
                Security and Accuracy of Information
              </p>
              <div>This website may vary from time to time.</div>
              <p className="tnc_new_text_highlighted mb-2">Third-party sites</p>
              <div>
                You may be able to access third-party sites from this website. We do
                not make any representations or claims in relation to the content,
                quality, or reliability of these sites, and our inclusion of a link
                to them does not imply that we have any relationship or affiliation
                with them.
              </div>
              <p className="tnc_new_text_highlighted mb-2">Password</p>
              <div>
                If you register with this website, you may be provided with a
                password. If you are provided with a password, you must keep it
                secret and secure.
              </div>
              <p className="tnc_new_text_highlighted mb-2">Disputes</p>
              <div>
                Any dispute between you and us will be determined under the laws of
                the UAE by the courts of the UAE.
              </div>
              {/* <div>
              <ul>
                <li>{t('generalProvision1')}</li>
                <li>{t('generalProvision2')}</li>
                <li>{t('generalProvision3')}</li>
                <li>{t('generalProvision4')}</li>
                <li>{t('generalProvision5')}</li>
                <li>{t('generalProvision6')}</li>
              </ul>
            </div> */}
              <p className="tnc_new_text_highlighted mb-2">
                ©Star Cinemas 2023. All rights protected.
              </p>
              {/* <p className="text-primary">{t('ArtMediaProductionGmbH')}</p> */}
            </article>
          )}
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(TnCNewContainer);
