import Api from './Api';

export default {
  JwtTokenCheck({ token }) {
    return Api().post('/api/cinema/check-jwt-token', { token });
  },
  Login(payload) {
    return Api().post('/api/cinema/sign-in', payload);
  },
  Register(payload) {
    return Api().post('/api/cinema/sign-up', payload);
  },
  GuestRegister(payload) {
    return Api().post('/api/guest-login', payload);
  },
  ContactusMail(payload) {
    return Api().post('/api/external/mail', payload);
  },
  GetUserHistory(payload) {
    return Api().post(
      `/api/cinema/user-history?cust_id=${payload.customer_id || Math.random()}`,
      payload,
    );
  },
  GetGuestHistory(payload) {
    return Api().post(
      `/api/external/guest-user-history?guest_id=${
        payload.guest_id || Math.random()
      }`,
      payload,
    );
  },
  GetCustomerHistoryV2(payload) {
    return Api().post(
      `/api/cinema/get-customers-history-v2?${
        payload.isCustomer ? 'cust_id' : 'guest_id'
      }=${payload.customer_id || Math.random()}&type=${payload?.type}`,
      payload,
    );
  },
  GetCustomerLoyaltyPoints(payload) {
    return Api().post(
      `/api/cinema/get-loyalty-points-web?${
        payload.isCustomer ? 'cust_id' : 'cust_id'
      }=${payload.customer_id || Math.random()}&type=${payload?.type}`,
      payload,
    );
  },
  UpdateUser(payload) {
    return Api().post(`/api/cinema/update-user`, payload);
  },
  UpdateUserLang(payload) {
    return Api().post(`/api/cinema/update-user-lang`, payload);
  },
  ForgotPassword(payload) {
    return Api().post(`/api/external/website-forgot-password`, payload);
  },
  VerifyOTP(payload) {
    return Api().post(`/api/external/website-verify-otp`, payload);
  },
  ResetPassword(payload) {
    return Api().post(`/api/external/website-change-password`, payload);
  },
  LoginViaOtp(payload) {
    return Api().post(`/api/external/website-login-via-otp`, payload);
  },
  sendAdvertiseMailStar(payload) {
    return Api().post('/api/external/advertiseMail', payload);
  },
  sendCareerMailStar(payload) {
    return Api().post('/api/external/CareerMailStar', payload);
  },
  verifyUserOTP(payload) {
    return Api().post('/api/cinema/verify-user', payload);
  },
  resendEmailOTP(payload) {
    return Api().post('/api/cinema/resendEmailOTP', payload);
  },
  sendMailPrivateBookingStar(payload) {
    return Api().post('/api/external/sendMailPrivateBookingStar', payload);
  },
};
