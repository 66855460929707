import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';

// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import CountryCodeDropdown from '@components/partials/CountryCodeDropdown';
import Button from '@components/partials/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { sendemail } from '@store/auth/authActions';
import {Helmet} from "react-helmet";

const ContactUsNewContainer = ({ t }) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState(``);
  const [firstName, setfirstName] = useState(``);
  const [lastName, setlastName] = useState(``);
  const [phone, setPhone] = useState(``);
  const [suggestions, setSuggestions] = useState(``);
  const [submitted, setSubmitted] = useState(false);
  const [status, setStatus] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const { pathname } = useLocation();

  const isBookEvent = pathname === '/book-event';

  let globalOptions = useSelector((state) => state.movies.globalOptions);
  const stc_id =
    globalOptions.find(
      (g) => g.go_key === `MYCINEMA_FEEDBACK_SUPPORT_TICKET_CATEGORY_ID`,
    )?.go_value ?? 0;
  const auth = useSelector((state) => state.auth);
  const cinema = useSelector((state) => state.movies.selectedCinema);
  const { loggedInUser } = auth;
  let customer_id = loggedInUser?.cust_id || null;
  let contact_no = loggedInUser?.cust_mobile || null;
  let cinema_id = cinema.cinema_id || null;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(function fieldMarginReducer() {
    const fields = document.querySelectorAll('.fields');
    fields.forEach(function (field) {
      console.log(field);
      if (
        field.nextElementSibling &&
        field.nextElementSibling.classList.contains('error')
      ) {
        field.style.marginBottom = '3px';
      }
    });
  });

  const submitForm = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (email && suggestions) {
      setValidForm(true);
    }
  };

  useEffect(() => {
    console.log(
      { validForm },
      {
        name: `User from Mycinema - ${firstName} ${lastName}`,
        email,
        contact_no,
        phone,
        message: suggestions,
        customer_id,
        cinema_id,
        stc_id,
      },
    );

    if (validForm) {
      dispatch(
        sendemail({
          name: `User from Mycinema - ${firstName} ${lastName}`,
          email,
          contact_no: !!contact_no ? contact_no : phone,
          message: suggestions,
          customer_id,
          cinema_id,
          stc_id,
        }),
      ).then(() => {
        setStatus(true);
      });
    }
  }, [validForm]);

  useEffect(() => {
    if (status) {
      setEmail('');
      setSuggestions('');
      setfirstName('');
      setlastName('');
      setPhone('');
    }
  }, [status]);

  let content = [
    {
      header: ['ContactUs.reservations'],
      text: ['ContactUs.reservationsBoxoffice'],
      phone: ['044 542 49 34'],
      email: [''],
    },
    {
      header: ['ContactUs.events'],
      text: ['Zoë Stähli'],
      phone: ['+41 (0)44 296 90 78'],
      email: ['zoe@stuessihof.ch'],
    },
    {
      header: ['ContactUs.bar'],
      text: ['Caroline Stirnemann'],
      phone: ['+41 (0)79 286 70 42'],
      email: ['caroline@stuessihof.ch'],
    },
    {
      header: ['ContactUs.administration'],
      text: ['Peter Preissle'],
      phone: ['+41 (0)44 296 90 73'],
      email: [
        'p.preissle@cinebox.ch', //'preissle@stuessihof.ch',
      ],
    },
    {
      header: ['Address'],
      text: ['Kino Stüssihof', 'Stüssihofstatt 13', '8001 Zürich'],
      phone: [''],
      email: [''],
    },
  ];

  return (
    <>
    <Helmet>
        <title>Contact Us:Get in Touch with Star Cinemas</title>
        <meta name="description" content={` Get in Touch with Star Cinemas`} />
      </Helmet>
      <div className="container-fluid contact_us_new">
        <CenteredTitle firstHalf={t('Contact')} secondHalf={t('Us')} />
        <section className="row">
          {/*
          <article className="col-md-6 col-lg-5 mb-4 mb-md-0">
            {content.map(c => {
              return (
                <div className="contact_us_new_section">
                  <h6>
                    {!!t(c.header[0]) ? t(c.header[0]) : c.header[0]}
                  </h6>
                  {c.text.map((txt, i) => (
                    !!txt && (<React.Fragment key={i}>
                      <p>
                        {!!t(txt) ? t(txt) : txt}
                      </p>
                    </React.Fragment>)
                  ))}
                  <p className="contact_us_new_text_highlighted">
                    {c.phone[0] && t('Telephone')}{' '}{c.phone[0]}
                  </p>
                  {!!c.email && (
                    <a className="contact_us_new_link" href={`mailto:${c.email[0]}`}>
                      {c.email[0]}
                    </a>)
                  }
                </div>
              )
            })}

            <p className="contact_us_new_text_highlighted text-primary">
              {t('ContactUs.note')}
            </p>
          </article>
*/}

          {/*<article className="col-md-6 col-lg-7">*/}
          <article className="col-12">
            <form className="contact_us_new_form" onSubmit={submitForm}>
              {/*<CenteredTitle firstHalf={t("Write to")} secondHalf={t("Us")}/>*/}
              <div className="row">
                <article className="col-lg-6">
                  <label>
                    <p>{t('login_form.First Name')}</p>
                    <input
                      type="text"
                      placeholder={t(
                        'login_form.placeholders.Enter your first name',
                      )}
                      value={firstName}
                      onChange={(e) => setfirstName(e.target.value)}
                    />
                    <p className="error">
                      {submitted &&
                        !status &&
                        firstName === '' &&
                        t('login_form.error_messages.First name is required')}
                    </p>
                  </label>
                </article>

                <article className="col-lg-6">
                  <label>
                    <p>{t('login_form.Last Name')}</p>
                    <input
                      type="text"
                      placeholder={t(
                        'login_form.placeholders.Enter your last name',
                      )}
                      value={lastName}
                      onChange={(e) => setlastName(e.target.value)}
                    />
                    <p className="error">
                      {submitted &&
                        !status &&
                        lastName === '' &&
                        t('login_form.error_messages.Last name is required')}
                    </p>
                  </label>
                </article>

                <article className="col-lg-6">
                  <label>
                    {/* <p>{t('Email')}</p>
                    <input
                      type="text"
                      placeholder={t('Enter your email address')}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    /> */}
                    <p className="error">
                      {submitted &&
                        !status &&
                        email === '' &&
                        t('Please enter your email')}
                    </p>
                  </label>
                </article>

                <article className="col-lg-6">
                  <label>
                    <p>{t('login_form.Phone Number')}</p>
                    <div className="d-flex">
                      <CountryCodeDropdown className="mr-2" />
                      <input
                        type="text"
                        min="10"
                        placeholder={t(
                          'login_form.placeholders.Enter your phone number',
                        )}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                    <p className="error">
                      {submitted &&
                        !status &&
                        phone === '' &&
                        t('Please enter your email')}
                    </p>
                  </label>
                </article>

                <article className="col-12">
                  <label>
                    <p>{t('Write a query of Feedback!')}</p>
                    <textarea
                      rows="6"
                      // placeholder="Type here..."
                      placeholder={t('type-suggestions')}
                      value={suggestions}
                      onChange={(e) => setSuggestions(e.target.value)}
                    />
                    <p className="error">
                      {submitted &&
                        !status &&
                        suggestions === '' &&
                        t('Please enter suggestions')}
                    </p>
                  </label>
                </article>
                <article className="col-md-10 col-lg-8 mx-auto">
                  {status && (
                    <div className="email-success-message">
                      {t('Message sent successfully')}{' '}
                    </div>
                  )}
                  <Button text={t('Submit')} />
                </article>
              </div>
            </form>
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(ContactUsNewContainer);
